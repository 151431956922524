import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  // {
  //   path: '/',
  //   name: 'yjh',
  //   component: () => import('../views/Yjh')
  // },
  // {
  //   path: '/',
  //   name: 'tqh',
  //   component: () => import('../views/Tqh3')
  // },
  // {
  //   path: '/',
  //   name: 'jd',
  //   component: () => import('../views/Jd')
  // },
  // {
  //   path: '/',
  //   name: 'zx',
  //   component: () => import('../views/zx/Zx')
  // },
  // {
  //   path: '/news',
  //   name: 'news',
  //   component: () => import('../views/zx/News')
  // },
  // {
  //   path: '/id',
  //   name: 'id',
  //   component: () => import('../views/zx/Id')
  // },
  // {
  //   path: '/zm',
  //   name: 'zm',
  //   component: () => import('../views/zx/Zm')
  // },
  // {
  //   path: '/gjj',
  //   name: 'gjj',
  //   component: () => import('../views/zx/Gjj')
  // },
  // {
  //   path: '/yuqi',
  //   name: 'yuqi',
  //   component: () => import('../views/zx/Yuqi')
  // },
  // {
  //   path: '/countdown',
  //   name: 'countdown',
  //   component: () => import('../views/zx/Countdown')
  // },
  // {
  //   path: '/report',
  //   name: 'report',
  //   component: () => import('../views/zx/Report')
  // },
  // {
  //   path: '/qs',
  //   name: 'qs',
  //   component: () => import('../views/zx/Qs')
  // },
  // {
  //   path: '/cd',
  //   name: 'cd',
  //   component: () => import('../views/zx/Cd')
  // },
  // {
  //   path: '/res',
  //   name: 'res',
  //   component: () => import('../views/zx/Res')
  // },
  // {
  //   path: '/service',
  //   name: 'service',
  //   component: () => import('../views/zx/Service')
  // },
  // {
  //   path: '/',
  //   name: 'yjh',
  //   component: () => import('../views/yjh/Yjh.vue')
  // },
  // {
  //   path: '/news',
  //   name: 'news',
  //   component: () => import('../views/yjh/News')
  // },
  // {
  //   path: '/phone',
  //   name: 'phone',
  //   component: () => import('../views/yjh/Phone')
  // },
  // {
  //   path: '/yuqi',
  //   name: 'yuqi',
  //   component: () => import('../views/yjh/Yuqi')
  // },
  // {
  //   path: '/gjj',
  //   name: 'gjj',
  //   component: () => import('../views/yjh/Gjj')
  // },
  // {
  //   path: '/shengri',
  //   name: 'shengri',
  //   component: () => import('../views/yjh/Shengri')
  // },
  // {
  //   path: '/report',
  //   name: 'report',
  //   component: () => import('../views/yjh/Report')
  // },
  // {
  //   path: '/countdown',
  //   name: 'countdown',
  //   component: () => import('../views/yjh/Countdown')
  // },
  {
    path: '/',
    name: 'wb',
    component: () => import('../views/wb/Wb.vue')
  },
  // {
  //   path: '/qs',
  //   name: 'qs',
  //   component: () => import('../views/wb/Qs')
  // },
  // {
  //   path: '/cd',
  //   name: 'cd',
  //   component: () => import('../views/wb/Cd')
  // },
  // {
  //   path: '/res',
  //   name: 'res',
  //   component: () => import('../views/wb/Res')
  // },
  // {
  //   path: '/',
  //   name: 'fjp',
  //   component: () => import('../views/fjp/Fjp2')
  // },
  // {
  //   path: '/qs',
  //   name: 'qs',
  //   component: () => import('../views/fjp/Qs')
  // },
  // {
  //   path: '/cd',
  //   name: 'cd',
  //   component: () => import('../views/fjp/Cd')
  // },
  // {
  //   path: '/res',
  //   name: 'res',
  //   component: () => import('../views/fjp/Res')
  // },
]

const router = createRouter({
  history: createWebHashHistory(),
  base: '/6/',
  routes
})

export default router
